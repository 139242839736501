const ASSETS = {
  PROFILE_IMAGE: "https://i.imgur.com/i87efmS.jpg",
  LINKEDIN_URL: "https://www.linkedin.com/in/marty-yee",
  GITHUB_URL: "https://github.com/MartyTheeMartian",
  EMAIL: "martyee1@gmail.com",
  PHONE: "(415) 819-8777",
  RESUME_PDF: "https://drive.google.com/file/d/1xoUiZOEAYK0NU70VEM7U8lzyXm0UQF7A/view",
  SCROLL_LINKS: {
    LEBRON: ["https://martyyee.net/portfolio#lebron", "https://martyyee.net/portfolio?project=lebron"],
    TROLLS: ["https://martyyee.net/portfolio#trolls", "https://martyyee.net/portfolio?project=trolls"],
    BEHIVE: ["https://martyyee.net/portfolio#behive", "https://martyyee.net/portfolio?project=behive"]
  },
  WALMART: {
    LEBRON_DESKTOP_VIDEO_ID: "788750516",
    LEBRON_MOBILE_VIDEO_ID: "788751263",
    TROLLS_2_VIDEO_ID: "799369137",
    DESKTOP_IMAGES: [
      "https://i.imgur.com/LK3cbFr.png",
      "https://i.imgur.com/m3r4XoX.png",
      "https://i.imgur.com/QSgrouU.png",
      "https://i.imgur.com/6dGIcHk.png",
      "https://i.imgur.com/0g9Pk9r.png",
    ],
    MOBILE_IMAGES: [
      "https://i.imgur.com/sV2A4hb.png",
      "https://i.imgur.com/Tt12sIE.png",
      "https://i.imgur.com/EP5nuyR.png",
      "https://i.imgur.com/eCWi0Z1.png",
      "https://i.imgur.com/cSEcli4.png",
    ]
  },
  BEHIVE: {
    MOBILE_IMAGES: [
      "https://i.imgur.com/B0Tzy3j.png",
      "https://i.imgur.com/1TOSHhC.png",
      "https://i.imgur.com/PP3jgTX.png",
      "https://i.imgur.com/g8PkQqo.png",
      "https://i.imgur.com/4G5pg7Q.png",
    ]
  }
};

export default ASSETS;
