import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.scss';


export default function Carousel({ images, mobile, autoPlay }) {
  return (
    <ReactCarousel
      autoPlay={autoPlay}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      emulateTouch={true}
      interval={3200}>
      {
        images.map((image) => 
          <div className="carousel-img-ctn" key={image}>
            <img className="carousel-img" loading="lazy" referrerPolicy="no-referrer" alt="screenshot" src={image} />
          </div>
        )
      }
    </ReactCarousel>
  );
}
