import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyCRLXcdsbm7UGIOJRSK_Dk_HSa0gTy-AJo",
  authDomain: "portfolio-86fbc.firebaseapp.com",
  projectId: "portfolio-86fbc",
  storageBucket: "portfolio-86fbc.appspot.com",
  messagingSenderId: "971933989406",
  appId: "1:971933989406:web:c137aa4173d047b9658c0f",
  measurementId: "G-35L28LVV3B"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
