import ASSETS from '../constants/assests';
import COPY from '../constants/copy';
import './Profile.scss';


export default function Profile() {

  const renderRoles = () => COPY.ROLES.map((role, i) => <li className="role-list" key={i + role}>{role}</li>);

  return (
    <div className="profile-ctn">
      <img className="profile-pic roll-in" referrerPolicy="no-referrer" alt={COPY.PROFILE_PIC_ALT} src={ASSETS.PROFILE_IMAGE} />
      <h1 className="header-name">
        {COPY.MY_NAME}
      </h1>
      <ul className="title-roles">
        {renderRoles()}
      </ul>
    </div>
  );
}
