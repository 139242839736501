import { ReactComponent as LinkedInIcon } from '../icons/linkedin.svg';
import { ReactComponent as GitHubIcon } from '../icons/github.svg';
import { ReactComponent as EmailIcon } from '../icons/email.svg';
import ASSETS from '../constants/assests';
import COPY from '../constants/copy';
import './Footer.scss';


export default function Footer() {
  return (
    <footer>
      <div className="icon-row-ctn">
        <a className="icon-ctn" target="_blank" rel="noreferrer" title={COPY.LINKEDIN} href={ASSETS.LINKEDIN_URL}> 
          <LinkedInIcon />
        </a>
        <a className="icon-ctn" target="_blank" rel="noreferrer" title={COPY.GITHUB} href={ASSETS.GITHUB_URL}> 
          <GitHubIcon />
        </a>
        <a className="icon-ctn" target="_blank" rel="noreferrer" title={COPY.EMAIL} href={COPY.MAIL_TO + ASSETS.EMAIL}>
          <EmailIcon />
        </a>
      </div>
      <div>
        {COPY.MY_NAME + ' ' + COPY.COPYRIGHT_SYMBOL + ' ' + new Date().getFullYear()}
      </div>
    </footer>
  );
}
