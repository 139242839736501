import { useState, useEffect, useRef } from 'react';
import Carousel from './basic/Carousel';
import { detectMobile } from '../helpers/detectMobile';
import ASSETS from '../constants/assests';
import './Portfolio.scss';


export default function Portfolio() {

  const portfolioTopRef = useRef(null);
  const lebronRef = useRef(null);
  const trollsRef = useRef(null);
  const behiveRef = useRef(null);

  const [isMobile, toggleIsMobile] = useState(false);
  const [slidesDisplay, toggleSlidesDisplay] = useState(false);

  const checkQueryParams = () => {
    const hash = window.location.hash.substring(1) || "";
    const search = window.location.search.split("=")[1] || "";

    console.log("hash:", hash);
    console.log("search:", search);

    switch (hash.trim() || search.trim()) {
      case "lebron":
        lebronRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "behive":
        behiveRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "trolls":
        trollsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        portfolioTopRef.current.scrollIntoView({ behavior: "smooth" });
        break;
    }
  }

  useEffect(() => {
    toggleIsMobile(detectMobile());
    setTimeout(() => {
      checkQueryParams();
    }, 1200);
  }, []);

  return (
    <div className="portfolio-ctn">
      {renderButtons(isMobile, toggleIsMobile)}
      <h2 ref={portfolioTopRef}>Portfolio</h2>

      <h3>Walmart</h3>

      <h4 ref={lebronRef}>Lebron James x Walmart: Fight Hunger</h4>

      <div id="lebron-video">
        {renderVideo(isMobile)}
      </div>

      { !slidesDisplay && <button className="show-slides-btn" onClick={() => toggleSlidesDisplay(true)}>Show Slides</button>}

      {renderSlides(slidesDisplay, isMobile)}

      <h4 ref={trollsRef}>Trolls 2: Animated + Audio Equalizer</h4>

      <div className="trolls-vid-ctn">
        {/* <div className="loading-spiner" /> */}
        <iframe id="trolls-vid" title="vimeo-player" src={`https://player.vimeo.com/video/${ASSETS.WALMART.TROLLS_2_VIDEO_ID}?h=a0e85790bd&muted=1&autoplay=1&loop=1&title=0&byline=0&portrait=0&sidedock=0`} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
      </div>

      <h3 ref={behiveRef}>Behive</h3>

      <h4>Hive News: Content Distribution</h4>

      <div className="mobile-carousel-ctn">
        <Carousel images={ASSETS.BEHIVE.MOBILE_IMAGES} mobile={true} autoPlay={false}/>
      </div>

    </div>
  );
}

const renderVideo = (isMobile) => {
  return isMobile ?
    (
      <div className="vid-mobile-ctn">
        {/* <div className="loading-spiner" /> */}
        <iframe id="lebron-vid-mobile" title="vimeo-player" src={`https://player.vimeo.com/video/${ASSETS.WALMART.LEBRON_MOBILE_VIDEO_ID}?h=a0e85790bd&muted=1&autoplay=1&loop=1&title=0&byline=0&portrait=0&sidedock=0&controls=0`} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
      </div>
    ) : 
    (
      <div className="vid-desktop-ctn">
        {/* <div className="loading-spiner" /> */}
        <iframe id="lebron-vid-desktop" title="vimeo-player" src={`https://player.vimeo.com/video/${ASSETS.WALMART.LEBRON_DESKTOP_VIDEO_ID}?h=a0e85790bd&muted=1&autoplay=1&loop=1&title=0&byline=0&portrait=0&sidedock=0&controls=0`} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen />
      </div>
    );
}

const renderButtons = (isMobile, toggleIsMobile) => {
  return isMobile ?
    (
      <div className="desktop-mobile-btn-ctn">
        <button onClick={() => toggleIsMobile(false)}>Desktop</button>
        <button disabled>Mobile</button>
      </div>
    ) :
    (
      <div className="desktop-mobile-btn-ctn">
        <button disabled>Desktop</button>
        <button onClick={() => toggleIsMobile(true)}>Mobile</button>
      </div>
    );
}

const renderSlides = (slidesDisplay, isMobile) => {
  if (!slidesDisplay) {
    return;
  }

  return isMobile ?
  (
    <div className="mobile-carousel-ctn">
      <Carousel images={ASSETS.WALMART.MOBILE_IMAGES} mobile={true} autoPlay={false} />
    </div>
  ) :
  <Carousel images={ASSETS.WALMART.DESKTOP_IMAGES} mobile={false} autoPlay={false} />
}
