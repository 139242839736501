import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import COPY from '../constants/copy';
import './NavBar.scss';


export default function App() {

  const initalLocation = window.location.pathname.substring(1);

  const [currentLocation, setCurrentLocation] = useState(initalLocation);

  const checkBoxRef = useRef(null);

  const navClick = navLink => {
    checkBoxRef.current.checked = false;

    setCurrentLocation(navLink);
  };

  return (
    <nav className="nav-bar">
      <input id="menu-toggle" type="checkbox" ref={checkBoxRef} />
      <label className="menu-button-container" htmlFor="menu-toggle" tabIndex="0">
        <div className="menu-button" />
      </label>
      <ul className="menu">
        {renderNavLinks(COPY.NAVLINKS, navClick, currentLocation)}
      </ul>
    </nav>
  );
}

const renderNavLinks = (navLinks, navClick, currentLocation) => {
  return navLinks.map(navLink => 
    navLink === currentLocation ? 
      (
        <li key={navLink}>
          <span className="current-location">{navLink}</span>
        </li>
      ) : 
      (
        <li key={navLink}>
          <Link className="nav-link" to={`/${navLink}`} onClick={() => navClick(navLink)}>{navLink}</Link>
        </li>
      )
  );
}
