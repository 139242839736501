import { useState } from 'react';
import ASSETS from '../constants/assests';
import './Contact.scss';


export default function Contact() {
  const [ phoneVisible, setPhoneVisible ] = useState(false);

  const showPhone = () => setPhoneVisible(true);

  return (
    <div className="contact-ctn">
      <h2>Contact</h2>
      <div className="contact-line">
        <b>Email:</b> {ASSETS.EMAIL}
      </div>
      {
        phoneVisible ? 
          (
            <div className="contact-line">
              <b>Phone:</b> {ASSETS.PHONE}
            </div>
          ) : 
          <button onClick={showPhone}>Show Phone</button> 
      }
    </div>
  );
}
