import { useRef, useEffect } from 'react';
import ASSETS from '../constants/assests';
import './Resume.scss';


export default function Resume() {

  const resumeTop = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      resumeTop.current.scrollIntoView({ behavior: "smooth" });
    }, 500);
  }, []);

  return (
    <div className="resume-ctn">
      <h2 ref={resumeTop}>Resume</h2>
      <a className="resume-link btn" target="_blank" rel="noreferrer" href={ASSETS.RESUME_PDF}>
        View as PDF
      </a>
      <div className="resume-body">

        <h3>Technical Skills</h3>

        <ul>
          <li>Javascript (ES5/ES6), React (16) + Redux, HTML5, CSS3</li>
          <li>Jest + Enzyme, SuperTest,  jQuery, Web Accessibility (ADA)</li>
          <li>Node + Express, Sequelize ORM, MySQL, PostgreSQL</li>
        </ul>

        <h3>Employment / Experience</h3>
        
        <h4>Walmart eCommerce</h4> <span className="resume-dates">July 2018 – March 2021</span>
        <br />
        Lead Front End Web Developer
        <ul>
          <li><b><a href={ASSETS.SCROLL_LINKS.LEBRON[0]} rel="noreferrer">LeBron James Landing Page:</a></b> led dev team in developing an interactive landing page featuring LeBron James and his Family Foundation with Walmart's Fight Hunger campaign, which helped Walmart reach 500+ million meals donated to food banks. The page's technical features included a short motion picture using CSS animations, interactive slides with sliding & fading images, and slide navigation system triggered through scrolling, mouse, or keyboard buttons</li>
          <li><b><a href={ASSETS.SCROLL_LINKS.TROLLS[0]} rel="noreferrer">Trolls 2:</a></b> led dev team in developing an animated equalizer with an interactive audio element in collaboration with Universal Studios, optimized to be SEO-friendly and drove sales of Trolls merchandise</li>
        </ul>
        Full Stack Web Developer
        <ul>
          <li><b>Item Page Content CMS:</b> core developer that led architecture & design of content management system for item pages, which increased traffic & purchase conversions</li>
          <li><b>Product Image Grabber:</b> developed an internal tool to download high resolution Walmart product images in bulk, widely used by Walmart product & design teams and improved workflow efficiency of the organization</li>
        </ul>

        <h4>Behive, Inc.</h4>
        <br />
        Lead Front End Developer <span className="resume-dates non-h3">Jan 2018 – July 2018</span>
        <ul>
          <li><b>Landing Page:</b> development, optimization, SEO</li>
          <li><b><a href={ASSETS.SCROLL_LINKS.BEHIVE[0]} rel="noreferrer">Hive News:</a></b> WebView mobile app for distributing news content</li>
          <li><b>Emails:</b> templates & campaigns</li>
        </ul>
        Front End Developer (Intern) <span className="resume-dates">Oct 2017 – Dec 2017</span>
        <div className="spacer" />

        <h4>Code For San Francisco (non-profit)	</h4><span className="resume-dates">July 2017 – July 2018</span>
        <p>
          Front End Developer: <b><a href="https://github.com/PursuanceProject" target="_blank" rel="noreferrer">Pursuance Project</a></b>
        </p>
        <h4>Freelance Consultant</h4><span className="resume-dates">July 2017 – Present</span>

        <h3>Education</h3>

        <h4><a href="https://www.galvanize.com" target="_blank" rel="noreferrer">Galvanize</a></h4> <span className="resume-dates">Jan 2017 – June 2017</span>	
        <p>
          <b>Immersive Full Stack:</b> 1,000+ hours of full stack web development curriculum, including computer science, front end, back end, team environments, and a full stack capstone project
        </p>		
        <h4>City College of San Francisco</h4><span className="resume-dates">2014 – 2015</span>
        <p>
          Java: Intro, Java: OOP, Java: Algorithms & Data Structures, Python: Intro
        </p>
      </div>
    </div>
  );
}
