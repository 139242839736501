import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route
} from 'react-router-dom';
import Profile from './components/Profile';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Resume from './components/Resume';
import Contact from './components/Contact';
import './App.scss';


export default function App() {
  return (
    <div className="App">
      <Router>
        <div className="page-ctn">
          <NavBar />
          <div className="main-ctn">
            <Profile />
            <Routes>
              <Route exact path="/about" element={<About />} />
              <Route exact path="/portfolio" element={<Portfolio />} />
              <Route exact path="/resume" element={<Resume />} />
              <Route exact path="/contact" element={<Contact />} />
              <Route exact path="/*"  element={<Navigate replace to="/about"/>} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </div>
  );
};
