const COPY = {
  MY_NAME: 'Marty Yee',
  COPYRIGHT_SYMBOL: '©',
  MAIL_TO: 'mailto:',
  PROFILE_PIC_ALT: 'Marty Yee profile pic',
  LINKEDIN: 'LinkedIn',
  GITHUB: 'GitHub',
  EMAIL: 'Email',
  ROLES: [
    'software engineer', 'frontend developer', 'web developer'
  ],
  NAVLINKS: [
    'about', 'portfolio', 'resume', 'contact'
  ],
  ABOUT_BODY: 'Marty Yee is a full stack web developer with a strong interest in problem solving and developing user-friendly apps. Previously, Marty had explored other career fields through education and work, such as business, hospitality, and healthcare, but none was more rewarding than applying his cognitive abilities to solve programming problems.',
};

export default COPY;
