import COPY from '../constants/copy';
import './About.scss';


export default function About() {
  return (
    <div className="about-ctn">
      <h2 className="about-title">About</h2>
      <p className="about-body">
        {COPY.ABOUT_BODY}
      </p>
    </div>
  );
}
